import * as React from "react"
import { createClient, Provider as UrlqProvider } from "urql"

const urqlClient = createClient({
  url: `https://independent-forensics.myshopify.com/api/2021-01/graphql.json`,
  fetchOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": "c30d4e0f66372d7f8ed254a9f2f1fb96",
    },
  },
})

export function SearchProvider({ children }) {
  return <UrlqProvider value={urqlClient}>{children}</UrlqProvider>
}
